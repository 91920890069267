import React, { useCallback, useEffect, useState } from 'react';


import { useDispatch, useSelector } from 'react-redux';


import styles from './PlusGemsShopTabPanel.css';
import GemsPromoBlock from '../../../../../molecules/GemsPromoBlock/GemsPromoBlock';
import { NotifyMessage } from '../../../../../molecules/NotifyMessage/NotifyMessage';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import UserService from '../../../../../services/UserService';
import { setTrySpendGems } from '../../../../../store/ducks/gems/gems';
import { gemsAmountSelector } from '../../../../../store/ducks/gems/gemsSelectors';
import {
  GemsAnalyticsRegistrationLocations,
  setSocialRegistrationLocation
} from '../../../../../store/ducks/leanplum/lpAnalytics';
import { SignInUpContainer } from '../../../../SignInUpContainer/SignInUpContainer';
import { PlusGemsShopLoggedInView } from '../PlusGemsShopLoggedInView/PlusGemsShopLoggedInView';


export const PlusGemsShopTabPanel = () => {
  const isLoggedIn = UserService.isUserLoggedIn();
  const trySpendGems = useSelector(({ trySpendGems }) => trySpendGems);
  const gemsAmount = useSelector(gemsAmountSelector);
  const dispatch = useDispatch();
  const [showNotify, setShowNotify] = useState<boolean>(false);

  useEffect(() => {
    setShowNotify(Boolean(trySpendGems));
    dispatch(setTrySpendGems(''));
  }, [dispatch, trySpendGems]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.SHOP));
    }
  }, [isLoggedIn]);

  const sendGemsAnalytics = useCallback(async () => {
    await Analytics.trackEvent(Analytics.gems.gemShopSignInUp(GemsAnalyticsRegistrationLocations.SHOP));
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <div className={styles.loggedInView}>
          {showNotify && gemsAmount === 0 && <NotifyMessage />}
          <PlusGemsShopLoggedInView />
        </div>
      ) : (
        <div className={styles.notLoggedInView}>
          <GemsPromoBlock />
          <SignInUpContainer onSignInUpFinished={sendGemsAnalytics} />
        </div>
      )}
    </>
  );
};
