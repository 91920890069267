import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';


import styles from './PlusGemCard.css';
import { MiscUtils } from '../../../../../../utils/MiscUtils';
import { LazyImage } from '../../../../../atoms/ProgressiveImage/LazyImage';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { LeanplumGemsTrack } from '../../../../../services/Analytics/LeanplumAnalytics';
import { LocalStorageService } from '../../../../../services/LocalStorage';
import { UrlService } from '../../../../../services/UrlService';
import UserService from '../../../../../services/UserService';
import { AppState } from '../../../../../store';
import { gemsShopLocationSelector } from '../../../../../store/ducks/gems/gemsSelectors';
import { LS_SHOP_LOCATION_PROP } from '../../../../../store/ducks/leanplum/lpAnalytics';
import { PurchaseNewTabHelper } from '../../../../../templates/Purchase/PurchaseNewTabHelper';

export type GemCardProps = {
  gemPackId: string;
  gemsAmount: number;
  imageSrc: string;
  price: number;
  oldPrice?: number;
  ribbon?: string;
  onClick?: () => void;
};

const GEM_IMAGE_SRC = UrlService.createURL('/images/gems/gemcard-gem.png', true);

export const PlusGemCard = React.memo(
  ({ gemPackId, gemsAmount, imageSrc, ribbon, price, oldPrice, onClick }: GemCardProps) => {
    const dispatch = useDispatch();
    const preparePrice = (price: number) => `$${!Number.isInteger(price) ? price.toFixed(2) : price}`;
    const currStateUser = useSelector(({ user }) => user);
    const isUserSubscriber = UserService.isUserSubscriber();
    const gemsShopLocation = useSelector(gemsShopLocationSelector);
    const shopLocation = LocalStorageService.getItem(LS_SHOP_LOCATION_PROP) || gemsShopLocation;
    const currStatePack = gemPackId;
    const games = useSelector(({ games }: AppState) => games);

    useEffect(() => {
      if (PurchaseNewTabHelper.isTabData()) {
        PurchaseNewTabHelper.clearTabData();
      }
    }, [PurchaseNewTabHelper.isTabData()]);

    const onClickHandler = async () => {
      const gameHref = UrlService.getSlugFromFullPath(window?.location?.href);
      const game = games?.find((game) => game.meta.alias === gameHref);
      const gameSlug = game?.meta?.alias;

      PurchaseNewTabHelper.gemCardClick({ currStatePack, currStateUser, dispatch, gameSlug });
      void LeanplumGemsTrack.virtualItemPurchaseClick({
        purchasableItemId: gemPackId,
        purchasableItemAmount: gemsAmount,
        shopLocation
      });
      await Analytics.trackEvent(Analytics.gems.gemPurchaseClick(shopLocation, price, gemPackId, gemsAmount));

      onClick?.();
    };

    return (
      <span
        className={classNames(styles.container, {
          [styles.altContainer]: ribbon
        })}
      >
        <div style={{ maxHeight: 100 }} className={styles.value}>
          <LazyImage className={styles.gem} img={GEM_IMAGE_SRC} alt={'Gem'} />
          {MiscUtils.numberWithCommas(gemsAmount)}
        </div>
        <div className={styles.graphicContainer}>
          <LazyImage
            className={classNames(styles.graphic, { [styles.altGraphic]: ribbon })}
            img={imageSrc}
            alt={'Graphic'}
          />
          {ribbon && (
            <div className={styles.ribbon}>
              <p className={styles.ribbonText}>{ribbon}</p>
            </div>
          )}
        </div>
        {!!oldPrice && <p className={styles.saving}>You save: {preparePrice(oldPrice - price)} </p>}
        <Button
          className={classNames(
            styles.button,
            { [styles.altButton]: oldPrice, [styles.subscriberButton]: isUserSubscriber }
          )}
          onClick={onClickHandler}
          outlined
        >
          {!!oldPrice && <span className={styles.oldPrice}>{preparePrice(oldPrice)}</span>}
          {preparePrice(price)}
        </Button>
      </span>
    );
  }
);
PlusGemCard.displayName = 'GemCard';
