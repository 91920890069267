import React from 'react';

import classNames from 'classnames';
import { TFunction } from 'i18next';

import styles from './PlusBenefitsList.css';
import { ListCheckCircleIcon } from '../../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';

const COLOR_CIRCLE_COLOR = '#FFFFFF';
const COLOR_CIRCLE_MAIN = '#12B76A';

interface IPlusBenefitsListProps {
  t: TFunction;
  noTopMargin?: boolean;
  isTrialAvailable: boolean;
}

export const PlusBenefitsList = ({ t, noTopMargin, isTrialAvailable }: IPlusBenefitsListProps) => (
  <ul className={classNames(styles.advantagesList, {
    [styles.noMargin]: noTopMargin
  })}>
    {isTrialAvailable && (
      <li>
        <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
        <p>{t('SELECT_SUBSCRIPTION.PLUS_THREE_DAY_TRIAL')}</p>
      </li>
    )}
    <li>
      <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
      <p>{t('SELECT_SUBSCRIPTION.PLUS_FREE_ADS')}</p>
    </li>
    <li>
      <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
      <p>{t('SELECT_SUBSCRIPTION.PLUS_SUBSCRIBER_ONLY_GAME')}</p>
    </li>
    <li>
      <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
      <p>{t('SELECT_SUBSCRIPTION.PLUS_BONUS_GEMS')}</p>
    </li>
    <li>
      <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
      <p>{t('SELECT_SUBSCRIPTION.PLUS_SAVE_PERCENT')}</p>
    </li>
    <li>
      <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
      <p>{t('SELECT_SUBSCRIPTION.PLUS_VIP_SUPPORT')}</p>
    </li>
  </ul>
);
