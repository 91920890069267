import React, { FC, useEffect, useState, memo } from 'react';

import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './PlusCard.css';
import { getPlanNameByCode, SubscriptionPlans } from '../../../../../constants/SubscriptionPlan';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { SubscriptionPlan } from '../../../../../models/Subscription/SubscriptionData';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { setActiveSubscriptionPlan } from '../../../../../store/ducks/subscription/common';

type AnnualCardProps = {
  plan: SubscriptionPlan | null;
  planType: string;
};
const PlusCard: FC<AnnualCardProps> = memo(
  ({ plan, planType }: AnnualCardProps) => {
    PlusCard.displayName = `${planType}Card`;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource);
    const [planPriceLabel, setPlanPriceLabel] = useState<number>();
    const [planPriceFull, setPlanPriceFull] = useState<number>();
    const [currencySign, setCurrencySign] = useState<string>();
    const isPromotionalPlan = planType === SubscriptionPlans.ANNUAL;
    const planName = getPlanNameByCode(plan?.code);

    useEffect(() => {
      const planFullPrice = plan?.currencies[0]?.unitAmount;
      const planPriceValue = planFullPrice / plan?.intervalLength;
      const planLabel = Math.floor(planPriceValue * 100) / 100;
      const planCurrency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs

      setPlanPriceFull(planFullPrice);
      setPlanPriceLabel(planLabel);
      setCurrencySign(planCurrency);
    }, [plan]);

    const handleSubscribe = (planType: string) => {
      void Analytics.trackEvent(
        Analytics.subscription.entryPointClick(
          subscriptionSource,
          isPromotionalPlan ? 'annual' : 'monthly'
        )
      );
      batch(() => {
        dispatch(setActiveSubscriptionPlan(planType));
        dispatch(push('/subscription'));
      });
    };

    return (
      <>
        {plan &&
		  <div className={classNames(styles.container, { [styles.promotional]: isPromotionalPlan })}>
            {isPromotionalPlan && (
              <div className={styles.cardHeader}>
                {`👑 ${t('PLUS_CARD.HEADER')}`}
              </div>
            )}
            {plan &&
			  <div className={styles.content}>
				<h3 className={styles.title}>
                  {planType}
				</h3>
				<p className={styles.priceTitle}>
                  {currencySign}
                  {planPriceLabel}
				  <span>{t('PLUS_CARD.PAYMENT_PERIOD')}</span>
				</p>
				<p className={styles.priceSubtitle}>
                  {t('PLUS_CARD.BILLED_AT')}
				  &nbsp;
				  <strong>
                    {currencySign}
                    {planPriceFull}
				  </strong>
				</p>
				<Button className={styles.btn} onClick={() => handleSubscribe(planName)}>
                  {isPromotionalPlan ? t('PLUS_CARD.BUTTON_LABEL_PROMOTIONAL') : t('PLUS_CARD.BUTTON_LABEL')}
				</Button>
			  </div>
            }
		  </div>
        }
      </>
    );
  }
);

export default PlusCard;
