import { RecurlyGemPackItems, RecurlyGemsItemNames } from './RecurlyGemPackItems';
import { SubscriptionPlans } from "./SubscriptionPlan";
import { environment } from '../config/environment';

const prefixPlan = environment?.RECURLY_PLAN_PREFIX;

export enum PaymentType {
  subscription = 'Arkadium Advantage',
  gems = 'Gems',
}

export type RecurlyPlanObject = {
  key: string;
  plan: string;
  title: string;
  description: string;
  price?: number;
  gemsAmount?: number;
};

const RecurlySubscriptionPlans = () => {
  const subscriptionPlans: any = {};

  Object.values(SubscriptionPlans).forEach((planName) => {
    (subscriptionPlans as any)[planName] = {};
    (subscriptionPlans as any)[planName].key = planName;
    (subscriptionPlans as any)[planName].plan = `${prefixPlan}aasub2025_arkcom_${planName.toLowerCase()}`;
    (subscriptionPlans as any)[planName].title = PaymentType.subscription;
    (subscriptionPlans as any)[planName].description = `${planName} subscription`;
  });
  return subscriptionPlans;
};
const getRecurlyGoodsNames = () => {
  const subscriptionPlans = RecurlySubscriptionPlans();
  const gemPackItems = RecurlyGemPackItems();

  return {
    [PaymentType.subscription]: { ...subscriptionPlans },
    [PaymentType.gems]: { ...gemPackItems }
  };
};

export const RecurlyGoodsNames = {
  [PaymentType.subscription]: ['Annual', 'Monthly'],
  [PaymentType.gems]: RecurlyGemsItemNames
};

export const RecurlyPlans = getRecurlyGoodsNames();

export function chooseGemsPacksPlan(isSubscriber: boolean, isTrialAvailable: boolean) {
  return RecurlyPlans[PaymentType.gems][(isSubscriber && !isTrialAvailable) ? 'forSubscribers' : 'forRegistered'];
}
